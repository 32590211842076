.banner {
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    background-position: center;
    width: 100%;
    height: 100vh;
    background:rgba(255, 236, 236, 0.795); 
    overflow: hidden;
    padding: 0ch;
}

.brand-statement {
    position: relative;
    width: 240ch;
    height: 90ch;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0ch;
    padding: 0ch;
    left: 0ch;
    margin: 0%;

}

.bubble {
    position: relative;
    display: block;
    width: 70%;
    height: 100%;
    background-image: url("../../Assets/Images/bubble.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 40ch;
    top: 0ch;
    padding: 20%;
    left: 14ch;
    margin: 0%;
}
.peace{
    width: 0;
    height: 0;
}

.brand-txt {
    display: flex;
    position: relative;
    font-family: 'Coiny', cursive;
    font-style: normal;
    width: 80%;
    font-weight: 400;
    padding-top: 0%;
    font-size: 50px;
    line-height: 45px;
    color: #707070;
    padding-left: 5%;
    text-shadow: 0px 4px 4px rgba(202, 52, 52, 0.25);
    margin: 0%;
    top: 0%;
}

.mobile-download {
    display: none;
}



.bg_img_change {
    display: flex;
    animation: changeBg 14s infinite;
    width: 80%;
    height: 20%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: contain;
    z-index: 0;
    padding: 8%;
    margin-left: 2%;
    margin-bottom: 6%;

}


@keyframes changeBg {

    0%,
    100% {

        background-image: url("../../Assets/Images/amazing1.png")
    }

    10% {

        background-image: url("../../Assets/Images/amazing2.png")
    }

    20% {

        background-image: url("../../Assets/Images/amazing3.png")
    }

    30% {

        background-image: url("../../Assets/Images/amazing4.png")
    }

    40% {

        background-image: url("../../Assets/Images/amazing5.png")
    }

    50% {

        background-image: url("../../Assets/Images/amazing6.png")
    }

    60% {

        background-image: url("../../Assets/Images/amazing7.png")
    }

    40% {

        background-image: url("../../Assets/Images/amazing8.png")
    }

    70% {

        background-image: url("../../Assets/Images/amazing9.png")
    }

    80% {

        background-image: url("../../Assets/Images/amazing10.png")
    }

    90% {

        background-image: url("../../Assets/Images/amazing11.png")
    }


}

.close-btn-lg {
    background-color: #ffffff00;
    position: relative;
    color: rgba(0, 0, 0, 0.795);
   text-align: right;

    font-family: 'Dongle', sans-serif;
    display: inline;
    border: none;
    line-height: 0;
    left: 23%;
    top: 2px;
    font-size: 70px;

    color:#858484;

}

.wrapper-sqr-about-me {
    top: 0ch;
    right: 0ch;
    z-index: 1;
    position: absolute;
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: rgb(255, 236, 236);
    border-radius: 10px 0px 10px 0px;
    overflow: hidden;

}

.about-me-bg {
    position: absolute;
    display: block;
    flex-direction: column;
    width: 50%;
    height: 80%;
    border-radius: 2ch;
    background: rgba(184, 207, 236, 0.473);
    box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;
    top: 5ch;
    padding-left: 10ch;
    padding-bottom: 20px;
    left: 65ch;
    margin: 0%;
    overflow: hidden;

}

.about-me-txt {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 98%;
    margin-right: 20px;
    height: 160px;
    word-wrap: break-word;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 55px;
    color: #818181;
    top: 20%;
    left: 0px;
    text-align: left;
    padding-right: 2ch;
    z-index: 3;
    
}

.about-me-title-lg{
    position: absolute;
    font-family: 'Coiny', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    margin: 0;
    width: 100%;
    left: 0;
    margin-top: 0px;
    padding: 30px;
    color: #818181;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgba(184, 207, 236, 0.473);
    border-radius: 20px 20px 0px 0px;
  
}
.about-me-title{
    display: none;
}



.close-btn-resume-lg {
    background-color: #ffffff00;
    position: relative;
    color: rgba(0, 0, 0, 0.795);
    font-family: 'Dongle', sans-serif;
    display: inline;
    border: none;
    line-height: 0;
    left: 23%;
    right: 0;
    top: 2px;
    bottom: 0;
    font-size: 70px;
    color:#858484;

}


.pdf-resume {
    width: 75ch;
    height: 91ch;
    top: 7ch;
    left: 8.2ch;
    position: absolute;
    display: block;
    align-items: center;



}
.resume-title{
    display: none;
}

.resume-title-lg{
    position: absolute;
    font-family: 'Coiny', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    margin: 0;
    width: 100%;
    left: 0;
    margin-top: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #818181;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(206, 221, 243);
    border-radius: 20px 20px 0px 0px;

}

.resume_container{
    width:92ch;
    height: 92%;
    position: relative;
    display: block;
    background:rgb(214, 203, 223);
    left: 35%;
    top: 2ch;
    z-index: 2;
    border-radius: 20px


}



@media screen and (max-width: 1450px) {
    .brand-statement {

        padding-left: 0ch;

    }

    .bubble {
        width: 70ch;
        padding-left: 0ch;
        padding: 20%;
        left: 15ch;
        top: 10ch;
    }

    .bg_img_change {
        width: 70%;
        left: 7ch;
        padding-bottom: 0ch;
        margin-right: 30ch;
    }

    .brand-txt {
        font-size: 34px;
        margin: 4%;
    }

}


@media screen and (max-width: 890px) {

    .banner {
        flex-wrap: wrap;
        flex-direction: row;
        background-position: center;
        width: 100%;
        height: 100vh;
        background-color: #f5e2e2;
        overflow-y: scroll;
        padding: 0h;
        padding-bottom: 12ch;
    }

    .brand-statement {
        position: relative;
        width: 60%;
        height: 50ch;
        top: -2ch;
        padding-left: 0ch;
        left: 20%;
        z-index: 0;



    }

    .brand-txt {

        font-size: 29px;
    }


    .about-me-bg {
        position: absolute;
        display: block;
        flex-direction: column;
        width: 100%;
        height: 95%;
        top: 39ch;
       padding: 2PX;
        left: 0;
        padding-bottom: 2ch;
        margin: 0%;
        overflow: hidden;

    }


    .bg_img_change {
        bottom: 3.4ch;
        left: 4ch;
    }


    .close-btn-lg {
      
        font-size: 70px;
        left: 10%;


    }

    .wrapper-sqr-about-me {
        top: 10ch;
        right: 0ch;
        z-index: 1;
        position: absolute;
        height: 990px;
        width: 100%;
        overflow: visible;
        background: rgb(247, 227, 227);

    }

   
    .bubble {
        height: 30ch;
        width: 60ch;
        top: 3ch;
        padding: 20%;
        left: 0ch;
        margin: 0;
        margin-top: 30px;
    }


    .about-me-txt {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 160px;
        font-size: 24px;
        top: 140px;
        left: 0px;
        padding-right: 0ch;
        z-index: 3;
        margin: 5px;
    }


    .close-btn-resume-lg {
    left: 10%;
    }

    .pdf-resume {
        display: none;
    }




    .resume-title{
        display: none;
        
    }
    .resume-title-lg{
        color: #818181;
        z-index: 1;
        
    }


    .mobile-download {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center ;
        top: 0ch;
        width: 100%;
     
        height: 20ch;
   
        padding: 0ch;
    
        left: 0;
        padding-top: 1ch;
        padding-bottom: 3ch;

        word-wrap: break-word;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 45px;
        color: #818181;
        margin: 0;
        border-radius: 20px;
        background: rgba(184, 207, 236, 0.473);
        box-shadow: none;
        



    }
    .resume_container{
        width:100%;
        height: 10ch;
        position: relative;
        display: block;
        background:rgba(214, 203, 223, 0);
        left: 0;
        top: 40ch;
        z-index: 0;
        
    
        
    }

}


@media screen and (max-width: 550px) {
    .banner {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        background-position: center;
        width: 100%;
        height: 100vh;
        background-color: #f5e2e2;
        overflow-y: scroll;
        padding: 0;
        left: 0;
        padding-bottom: 0ch;
    }

    .brand-statement {
        position: relative;
        justify-content: center;
        width:60%;
        height: 100%;
        top: 5ch;
        padding-left: 0ch;
        left: 0ch;
        z-index: 0;
    }  
    
    .bubble {
        height: 30ch;
        width: 60ch;
        top: 9ch;
        padding: 20%;
        left: 0ch;
        margin: 0;
     
    }


    .brand-txt {
        font-size: 29px;
    }

    .pdf-resume {
        display: none;
    }

 

    .about-me-bg {
        position: relative;
        width: 100%;
        height: 60%;
        top: 0ch;
        border-radius: 0;
        padding: 0;
        left: 0ch;
        box-shadow: none;
        border-bottom: 2px solid #707070;
   

    }

    .about-me-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        color: #818181;
        background-color:rgb(211, 228, 243);
        font-family: 'Coiny', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 45px;
        margin: 0;
        margin-top: 55px;
        padding: 10px;
       border-top: 2px solid #707070;
       border-bottom: 2px solid #707070;
       text-shadow: 0px 4px 4px rgba(202, 52, 52, 0.25);

    }

    .bg_img_change {
        background-image: url("../../Assets/Images/amazing2.png"); 
        width: 60%;
        animation: none;
        height: 20%;
        padding: 8%;
        margin-left: 4%;
        margin-bottom: 9%;
    }


    .close-btn {
        background-color: #ffffff00;
        position: relative;
        color: #818181;
        font-family: 'Dongle', sans-serif;
        border: none;
        line-height: 29px;
        font-size: 70px;
        text-shadow: 1px 0px 0px rgba(202, 52, 52, 0.25);
        display: inline;
        border: none;
        line-height: 0;
        left: 10%;
        right: 0;
        top: 2px;
        bottom: 0;
        font-size: 70px;
    
    }

    .wrapper-sqr-about-me {
        top: 40ch;
        right: 0ch;
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: visible;
        align-items: center;
        background: rgb(247, 227, 227);
    }

 

    .about-me-txt {
        position: relative;
        text-align: center;
        width:98%;
        height: 130px;
        font-size: 18px;
        line-height: 35px;
        top: 25px;
        left: 0px;
        margin: 0px;
        z-index: 3;
    }


    .close-btn-resume {
        background-color: #ffffff00;
        position: relative;
        color: #818181;
        font-family: 'Dongle', sans-serif;
        display: inline;
        border: none;
        line-height: 0;
        left: 10%;
        right: 0;
        top: 2px;
        bottom: 0;
        font-size: 70px;
  
 
   

    }

    .resume-title{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        color: #818181;
        background-color: rgb(211, 228, 243);
        font-family: 'Coiny', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 45px;
        margin: 0;
        margin-top: 55px;
        padding: 10px;
        border-top: 2px solid #707070;
        border-bottom: 2px solid #707070;
       text-shadow: 0px 4px 4px rgba(202, 52, 52, 0.25);
    }


    .mobile-download {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center ;
        top: 0ch;
        width: 100%;
     
        height: 20ch;
   
        padding:0; 
    
        left: 0;
        padding-top: 1ch;
        padding-bottom: 3ch;
      
        word-wrap: break-word;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 45px;
        color: #818181;
        margin: 0;
        border-radius: 0;
        background: rgba(184, 207, 236, 0.473);
        box-shadow: none;
        
        border-bottom: 2px solid #707070;


    }
    .resume-title-lg{
        display: none;
    }
    .about-me-title-lg{
        display: none;
    }
    .resume_container{
        width:100%;
        height: 10ch;
        position: relative;
        display: block;
        background:rgba(214, 203, 223, 0);
        left: 0;
        top: 0ch;
        z-index: 0;
        border-radius: 0px
    
        
    }
    .peace{
   
            width: 25ch;
            height: 25ch;
            position: relative;
            padding-top: 25%;
            z-index: 2;
            left: 0ch;
            background: rgba(128, 153, 184, 0);
            padding-left: 80px;
            padding-right: 80px;

        
        
    }
}