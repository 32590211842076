.side-nav-bar {
    position: relative;
    left: 0ch;
    top: 0ch;
    width: 340px;
    height: 120ch;
    margin: none;
    padding: 10px;
    z-index: 2;
    background: rgb(185, 210, 211);
    display: block;
    flex-direction: column;
    box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;



}


.connect-links {
    display: block;
    height: 60ch;
    width: 100%;
    position: relative;
    top: 5ch;
    left: 6ch;
    background-color: #f5e2e200;
    transition: 0.5s;
    margin: 0%;


}

.connect-links button {
    display: block;
    padding: 30px 30px 30px 32px;
    text-decoration: none;
    font-size: 30px;
    font-family: 'Coiny', cursive;
    color: #818181;
    display: block;
    transition: 0.3s;
    text-align: left;
    z-index: 22;
    border: none;
    margin: 3%;
    background: rgba(231, 222, 203, 0);
    border-bottom: 2px solid #818181;
    text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
}

.side-nav-bar button:hover {
    color: rgba(58, 129, 134, 0.58);
    text-shadow: 0px 4px 4px rgba(38, 63, 63, 0.144);
    font-size: 32px;
}


.londa {
    display: block;
    position: relative;
    width: 467px;
    height: 99px;
    right: 2ch;
    top: 0ch;
    padding-bottom: 1ch;
    font-family: 'Coiny', cursive;


    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 60px;
    color: #818181;
    text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
}

.circle-links {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 22ch;
    height: 9ch;
    padding-left: 2ch;
    left: 5ch;
    justify-content: space-between;
    align-items: center;
}

.email-img:hover, .linkedin-img:hover , .github-img:hover {
    height: 88px;
    width: 42px;
}


.email-img {
    background-image: url("../../Assets/email.png");
    position: relative;

    display: block;
    height: 80px;
    width: 35px;
    background-size: contain;
    padding-left: 2ch;
    background-repeat: no-repeat;
}

.github-img {

    background-image: url("../../Assets/github.png");
    position: relative;
    display: block;
    height: 80px;
    width: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 2ch;

}

.linkedin-img {
    background-image: url("../../Assets/linkedin.png");
    position: relative;
    display: block;
    height: 80px;
    width: 35px;
    background-size: contain;
    padding-left: 2ch;
    background-repeat: no-repeat;
}


.btn-links-projects {
    display: block;
    text-decoration: none;
    font-family: 'Coiny', cursive;
    font-size: 30px;
    color: #818181;
    display: block;
    transition: 0.3s;
    text-align: left;
    border: none;
    margin: 0%;
    background: rgba(231, 222, 203, 0);
    text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
}

.btn-links-projects:hover {
    color: rgba(58, 129, 134, 0.58);
    text-shadow: 0px 4px 4px rgba(38, 63, 63, 0.144);
    font-size: 32px;
}



@media screen and (max-height: 450px) {
    .connect-links {
        padding-top: 15px;
    }

    .side-nav-bar {
        padding-top: 15px;
    }

    .side-nav-bar a {
        font-size: 18px;
    }
}



@media screen and (max-width: 890px) {
    .side-nav-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 30%;
        width: 100%;
        top: 10px;
        left: 0ch;
        right: 0;
        background-color: rgba(240, 248, 255, 0);
        box-shadow: none;
        margin: none;
        padding: 0px;
        z-index: 2;

    }

    .side-nav-bar button {
        font-size: 24px;
        left: 0px;

    }

    button.btn-links {
        padding: 10px;
        width: 102px;
        height: 78px;
        text-align: center;
        border: none;
        text-decoration: underline;

    }

    .londa {
        display: block;
        position: relative;
        width: 100%;
        height: 99px;
        right: 0ch;
        top: 0ch;

        padding-bottom: 1ch;
      
        text-align: center;
        letter-spacing: 0.1em;
        color: #818181;
        text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
        font-size: 55px;
        top: 0ch;
        padding-top: 20px;
        left: 0px;
      
    }

    .side-nav-bar button:hover {

        font-size: 24px;
    }

    .btn-links-projects:hover {

        font-size: 24px;
    }

    .circle-links {
        width: 100%;
        height: 3ch;
        left: 2ch;
        align-items: center;
        text-align: center;
        padding-left: 0ch;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-left: 0ch;
        align-items: center;
    }

    .connect-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 10ch;
        width: 100%;
        font-size: 16px;
        left: 0;
        right: 0;
    }

    .btn-links-projects {

        font-size: 24px;
        text-align: left;
    }

    .email-img {
        height: 50px;
        width: 90px;
    }

    .github-img {

        height: 50px;
        width: 90px;

    }

    .linkedin-img {

        height: 50px;
        width: 90px;

    }
    .email-img:hover, .linkedin-img:hover , .github-img:hover {
        height: 50px;
        width: 90px;
    }


}

@media screen and (max-width: 540px) {

    .side-nav-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 30%;
        width: 100%;
        top: 10px;
        left: 0ch;
        right: 0;
        background-color: rgba(240, 248, 255, 0);
        box-shadow: none;
        margin: none;
        padding: 0px;
        z-index: 2;




    }

    .side-nav-bar button {
        font-size: 20px;
        left: 0px;

    }

    button.btn-links {
        padding: 10px;
        width: 102px;
        height: 78px;
        text-align: center;
        border: none;
        text-decoration: underline;

    }

    .londa {
        display: block;
        position: relative;
        width: 100%;
        height: 99px;
        right: 0ch;
        top: 0ch;

        padding-bottom: 1ch;
        background-color: rgb(211, 228, 243);
        text-align: center;
        letter-spacing: 0.1em;
        color: #818181;
        text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
        font-size: 55px;
        top: 0ch;
        padding-top: 20px;
        left: 0px;
        border-bottom: 2px solid #818181;
        border-top: 2px solid #818181;
    }

    .side-nav-bar button:hover {

        font-size: 20px;
    }

    .btn-links-projects:hover {

        font-size: 23px;
    }

    .circle-links {
        width: 100%;
        height: 3ch;
        left: 2ch;
        align-items: center;
        text-align: center;
        padding-left: 0ch;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-left: 0ch;
        align-items: center;
    }

    .connect-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 10ch;
        width: 100%;
        font-size: 16px;
        left: 0;
        right: 0;
    }

    .btn-links-projects {

        font-size: 20px;
        text-align: left;

    }

    .email-img {
        height: 50px;
        width: 90px;
        padding: 0;

    }

    .github-img {

        height: 50px;
        width: 90px;
        padding: 0;

    }

    .linkedin-img {

        height: 50px;
        width: 90px;
        padding: 0;

    }
    .email-img:hover, .linkedin-img:hover , .github-img:hover {
        height: 50px;
        width: 90px;
    }

}