.images {
    height: 25%;
    width: 80%;
    position: relative;
    box-shadow: 5px 5px 10px rgba(15, 15, 15, 0.58);
    border-radius: 2%;
    left: 9%;
    align-items: center;
    margin-top: 2ch;
}

.portfolios-container__portfolio {
    color: rgb(107, 108, 109);
    font-size: 20px;
    color: #818181;
    display: table;
    margin-top: 8ch;
    text-align: left;
    height: 70ch;
    width: 80%;
    line-height: 45px;
    border-radius: 2ch;
    background-color: rgba(184, 207, 236, 0.473);
    box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;
}

.portfolios-container__portfolio-title {
    font-size: 20px;
    font-family: 'Coiny', cursive;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: #818181;
    line-height: 45px;
    left: 4ch;
    width: 90%;
    position: relative;
    text-shadow: 0px 4px 4px rgba(238, 146, 200, 0.25);
}

.portfolios-container__portfolio-title h1 {
    font-weight: 400;
    width: 100%;
    text-align: center;
    position: relative;
}

.portfolios-container_portfolio-text p {
    position: relative;
    height: 12ch;
    width: 54ch;
    left: 5ch;
    width: 90%;
    overflow: hidden;
}

.homeNav_container {
    position: fixed;
    left: 0ch;
    top: 0ch;
    height: 100%;
    width: 340px;
    margin: none;
    padding: 10px;
    z-index: 2;
    background: rgb(185, 210, 211);
    display: block;
    flex-direction: column;
    box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;
}

.londa_home {

    position: relative;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 66px;
    line-height: 45px;
    color: #818181;
    padding-top: 0ch;
    top: 0ch;
    left: 2ch;
}

.project-home {
    display: block;
    padding: 30px 40px 30px 30px;
    text-decoration: none;
    font-size: 30px;
    font-family: 'Coiny', cursive;
    color: #818181;
    display: block;
    transition: 0.3s;
    text-align: center;
    border: none;
    margin: 25%;
    background: rgba(231, 222, 203, 0);
    border-bottom: 2px solid #818181;
    text-shadow: 0px 4px 4px rgba(187, 75, 99, 0.308);
}

.project-home:hover {
    color: rgba(44, 107, 111, 0.58);
    border-bottom: 2px solid rgba(44, 107, 111, 0.58);
    font-size: 32px;
}



/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: #f5e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(223, 171, 210, 0);
    border-radius: 100ch;
    width: 2ch;
    height: 3ch;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(235, 170, 170);
}

.btn_github_prg {
    position: relative;
    display: inline;
    text-align: center;
    padding: 1ch;
    left: 20%;
    background-color: rgba(228, 228, 228, 0);
    text-decoration: none;
    font-size: 27px;
    color: #818181;
    border-radius: 2ch;
    background-color: rgba(184, 207, 236, 0);
    text-decoration: underline;
    text-shadow: 0px 4px 4px rgba(202, 52, 52, 0.25);

}

.btn_github_prg:hover {
    color: rgba(58, 129, 134, 0.596);
    text-shadow: 0px 4px 4px rgba(38, 63, 63, 0.151);

}


@media screen and (max-width: 890px) {

    .images {
        height: 80%;
        width: 80%;
        border-radius: 2%;
        left: 2ch;
    }

    .portfolios-container__portfolio {
        color: rgb(107, 108, 109);
        font-size: 20px;
        color: #818181;
        display: table;
        margin-top: 0ch;
        text-align: center;
        padding-top: 2ch;
        height: 70ch;
        margin-bottom: 2ch;
        width: 80%;
        line-height: 45px;
        border-radius: 2ch;
        background-color: rgba(184, 207, 236, 0.473);
        box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;
    }

    .portfolios-container__portfolio-title {
        font-size: 20px;
        left: 0ch;

        text-align: center;
        font-size: 20px;
        color: #818181;
        margin-right: 0;
        line-height: 45px;
        position: relative;
        text-shadow: 0px 4px 4px rgba(238, 146, 200, 0.25);

    }

    .portfolios-container__portfolio-title h1 {
        width: 100%;
        left: 20px;
        text-align: center;
        padding: 2px;
        margin: 2px;

    }

    .portfolios-container_portfolio-text p {
        position: relative;
        height: 30%;
        width: 90%;

        left: 2ch;
        word-wrap: break-word;

    }

    .homeNav_container {


        left: 0ch;
        top: 2ch;
        width: 100%;
        height: 43ch;
        margin: none;
        padding: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0px;
        z-index: 2;
        flex-direction: column;
        box-shadow: none;
        background-color: rgba(240, 248, 255, 0);




    }

    .londa_home {
        top: 1ch;
        left: 0px;
    }

    .project-home {

        height: 2ch;
        width: 100%;
        font-size: 16px;
        font-size: 24px;
        text-align: center;
        background-color: rgba(240, 248, 255, 0);
        left: 0;
        top: 0;
        padding: 0;
        line-height: 60px;
        padding-top: 30px;
        margin-left: 0ch;
        margin-top: 0ch;
        border: none;
        text-decoration: underline;

    }

    .project-home:hover {
        color: rgba(44, 107, 111, 0.58);
        border-bottom: none;
        font-size: 24px;
    }


    .btn_github_prg {

        font-size: 19px;
        left: 20px;
        text-align: center;
        padding: 1ch;

    }


}

@media screen and (max-width: 540px) {

    .images {
        height: 25%;
        width: 80%;
        position: relative;
        box-shadow: 5px 5px 10px rgba(15, 15, 15, 0.58);
        border-radius: 2%;
        left: 0%;
        align-items: center;
        margin-top: 2ch;
    }

    .portfolios-container__portfolio {
        margin-bottom: 3ch;
        margin-top: 0ch;
        height: 45ch;
        padding: 1ch;
        text-align: center;
        width: 100%;
        border-radius: 0;
        background-color: rgba(184, 207, 236, 0.473);
        box-shadow: none;
        border-bottom: 2px solid #818181;
        border-top: 2px solid #818181;
        box-shadow: rgba(99, 9, 9, 0.25) 0px 25px 50px -12px;



    }

    .portfolios-container__portfolio-title {
        font-size: 20px;
        left: 0ch;
        text-align: center;
        font-size: 20px;
        color: #818181;
        line-height: 45px;

        width: 100%;
        position: relative;
        text-shadow: 0px 4px 4px rgba(238, 146, 200, 0.25);

    }

    .portfolios-container__portfolio-title h1 {
        width: 100%;
        font-size: 20px;
        text-align: center;
        padding: 0;
        left: 0;
        margin: 0;
    }

    .portfolios-container_portfolio-text p {
        position: relative;
        height: 30%;
        width: 90%;
        text-align: center;

        left: 2ch;
        word-wrap: break-word;

    }


    .homeNav_container {

        left: 0ch;
        top: 2ch;
        width: 100%;
        height: 43ch;
        margin: none;
        padding: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0px;
        z-index: 2;
        flex-direction: column;
        box-shadow: none;


    }

    .londa_home {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 66px;
        line-height: 45px;
        color: #818181;
        padding-top: 0ch;
        top: 0ch;
        left: 0ch;


    }

    .project-home {

        height: 2ch;
        width: 100%;
        font-size: 16px;
        font-size: 24px;
        text-align: center;
        left: 0;
        top: 0;
        padding: 0;
        line-height: 60px;
        padding-top: 30px;
        margin-left: 0ch;
        margin-top: 0ch;
        border: none;
        text-decoration: underline;

    }


    .btn_github_prg {

        font-size: 14px;
        left: 0ch;
        text-align: center;
        padding: 8px;

    }

    .project-home:hover {
        color: rgba(44, 107, 111, 0.58);
        border-bottom: none
    }

}