.footer-container {
    background-color: rgb(243, 222, 222);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    color: #707070;
    z-index: 2; 
}

.copyright {
    position: relative;
    text-align: center;
    width: 100%;
    bottom: 1ch;
}

.mepic {
    width: 60ch;
    height: 50ch;
    position: relative;
    top: 57ch;
    left: 0ch;
    z-index: 0;
    background-color: #24242400;
    bottom: 0;

}


@media screen and (max-width: 1380px) {

    .mepic {
        left: 1%;
        width: 50ch;
        height: 40ch;
        top: 67ch;

    }
}




@media screen and (max-width: 890px) {
    .footer-content {
        display: none;


    }



    .mepic {
        position: absolute;
        top: 85ch;
    }

}
@media screen and (max-width: 550px){
    .mepic {
        position: absolute;
        top: 73ch;
       
    }
    .footer-container {
        display: none;


    }

}