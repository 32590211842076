.home {
    text-align: center;
    position: relative;
    background: rgb(245, 224, 224);
    background-position: center;
    flex-wrap: nowrap;
    background-size: cover;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    padding: 0px; 
    min-height: 0; 
    overflow: hidden;
  }
  
  .projects {
    text-align: center;
    background:  rgb(245, 227, 227);
    background-position: center;
    background-size: cover;
    height: 310ch; 
    padding: 0px; 
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;

  }

  .each-project-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2ch;
    text-align: left;
    left: 60ch;
    top: 0px;
    width: 70%;
    height: 200ch;
    border-radius: 0%; 
   
}


/* width */
::-webkit-scrollbar {
  width: 0px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:#f5e2e200;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(223, 171, 210, 0);
  border-radius: 100ch;
  width: 2ch;
  height: 3ch;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(235, 170, 170, 0);
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(185, 210, 211);
  z-index: 1;
}

.spinner {
  color: rgb(252, 218, 218);
  font-size: 30px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 
    2em -2em 0 0em, 3em 0 0 -1em, 
    2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
    3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 
    2em -2em 0 0, 3em 0 0 0.2em, 
    2em 2em 0 0, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
     -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
     -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
     -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
    3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


  @media only screen and (max-width: 890px) {
    .home {
      padding: 0px;
      
    }
    .projects {
      align-items: center;
      overflow-y: scroll;
      display: block;
      height: 100%;
      overflow-y: scroll;
     
  
    }
  
    .each-project-container {
      padding: 0;
      text-align: left;
      left: 10%;
      
      top: 0ch;
      width: 100%;
      height: 210ch;
      border-radius: 0%;   
  }
  }

  
@media screen and (max-width: 540px){
  .projects {
    align-items: center;
    overflow-y: scroll;
    display: block;
    height: 100%;
    overflow-y: scroll;
    

  }
  .each-project-container {
    padding-bottom: 2ch;
    align-items: center;
    width: 100%;
    height: 210ch; 
    top: 0px;
    margin: 0;
    left: 0;
    padding-bottom: 0px;
}
}