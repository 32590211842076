body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgba(250, 234, 234, 0.795);
  z-index: -1;
  
}

#root {
  height: 100%;
  width: 100%;

  margin: 0;
  background: rgb(255, 255, 255);
 }

@media screen and (max-width: 890px) {
  html,
    body {
      position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px; 
  margin: 0;

}
#root {
  height: 100%;
  padding: 0px; 
  margin: 0;
  align-items: center;
   background: rgba(250, 234, 234, 0.795);
 }


}
